<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          障害者一般車両 一覧
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  こちらは障害者一般車両の一覧画面です。<br>
                  対象駐車場を選択すると、対象駐車場の車両のみが表示されます。
                </small>
              </v-alert>
            </v-col>
        </v-row>
      </v-card-text>

      <v-container v-if="drawer">
        <template>
          <v-row>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="店舗名"
                rules="required"
              >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :disabled="!$store.getters.isAdminUser"
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>

            <!-- <v-col v-if="selectedRequestClientStore && parkings.length > 0" cols="12" lg="6" class="py-0">
              <v-select
                outlined
                dense
                v-model="selectedParking"
                return-object
                :items="parkings"
                item-text="PARKING_NAME"
                item-value="PARKING_ID"
                label="対象駐車場"
              ></v-select>
            </v-col> -->
          </v-row>

          <v-row v-if="selectedRequestClientStore && parkings.length > 0" cols="6" class="pt-0 pb-6">
            <v-col>
              <v-text-field
                v-model="searchCarNumber"
                append-icon="mdi-magnify"
                outlined
                dense
                clearable
                hide-details
                label="車両番号検索"
              ></v-text-field>
            </v-col>

            <v-col>
              <div class="d-flex align-center justify-end">
                <div class="text-caption pl-4">表示件数</div>
                <div style="width: 100px;" class="pl-2">
                  <v-select
                    outlined
                    dense
                    v-model="itemsPerPage"
                    :items="pageItems"
                    item-text="text"
                    item-value="value"
                    hide-details
                  ></v-select>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <template v-if="selectedRequestClientStore">
        <v-card-text class="pa-3 pt-0">
          <v-data-table
            :items="filteredVehicles"
            item-key="id"
            
            :page.sync="page"
            :headers="selectHeaders"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
            no-data-text=""
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            dense
          >
            <template v-slot:body="{ items, headers }">
              <tbody v-if="items.length > 0 && !isLoading">
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @click.stop="showDialog(item)"
                  :class="['table-row', { 'deleted-item': item.deleted_at !== null }]"
                >
                  <td class="px-2 text-left hidden-md-and-down">{{ registedDateFormatted(item) }}</td>
                  <td class="px-2 text-left">{{ carInfo(item) }}</td>
                  <td class="text-left" :class="nearDeadlineStyle(item)" v-html="effectivePeriod(item)"></td>
                  <td class="text-left hidden-md-and-down">{{ item.note }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="!isLoading">
                <tr>
                  <td :colspan="headers.length" class="grey--text" style="text-align: center;">No results</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
            店舗address情報が設定されていないため情報を取得できません
          </v-container>
          <v-container v-else-if="!isLoading && accessibleVehicles.length == 0" class="text-center my-10">
            該当する障害者一般車両がありません
          </v-container>

          <v-container v-if="isLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-divider class="mt-0 mb-1"></v-divider>
        </v-card-text>
        <v-row v-if="accessibleVehicles.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>

      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>

    <!-- 詳細画面 -->
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          障害者一般車両 登録詳細
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
          ref="modal_content"
          class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="登録日時"
                :value="registedDateFormatted(showedVehicle)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="車両情報"
                :value="carInfo(showedVehicle)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0">
              <div class="v-text-field v-text-field--dense v-text-field--readonly">
                <label class="v-label text-caption">有効期間</label>
                <div class="v-input__control">
                  <div class="v-input__slot">
                    <div v-html="effectivePeriod(showedVehicle)" class="effective-period"></div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="py-4">
              <v-text-field
                dense
                readonly
                label="備考"
                :value="showedVehicle.note"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="駐車場情報"
                :value="parkingName(showedVehicle)"
              ></v-text-field>
            </v-col> -->

          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            color="blue-grey"
            @click="close"
          >閉じる</v-btn>

          <v-btn
            small
            outlined
            color="error"
            @click.stop="deleteConfirmDialog = true"
            v-if="showedVehicle.deleted_at === null"
          >削除する</v-btn>
          <v-btn
            small
            outlined
            color="warning"
            @click="edit"
            v-if="showedVehicle.deleted_at === null"
          >変更する</v-btn>

          <v-btn
            small
            outlined
            color="info"
            @click.stop="restoreDeletedConfirmDialog = true"
            v-if="showedVehicle.deleted_at !== null"
          >削除を元に戻す</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除 確認画面 -->
    <v-dialog
      v-model="deleteConfirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          削除してよろしいですか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="closeDeleteConfirmDialog">キャンセル</v-btn>
          <v-btn small outlined color="error" @click="deleteVehicle" :loading="deleteLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除を元に戻す 確認画面 -->
    <v-dialog
      v-model="restoreDeletedConfirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          削除を元に戻してよろしいですか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="closeRestoreDeletedConfirm">キャンセル</v-btn>
          <v-btn small outlined color="info" @click="restoreDeletedVehicle" :loading="restoreDeleteLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import moment from "moment"

export default {
  name: 'AccessibleVehicles',
  data: () => ({
    request_client_stores: [],
    selectedRequestClientStore: null,
    errors: [],

    drawer: true,
    isLoading: false,
    updateLoading: false,
    deleteLoading: false,
    restoreDeleteLoading: false,

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    headers: [
      { text: '登録日時', value: 'create_dte', sortable: true, filterable: false },
      { text: '車両番号', value: 'NUMBER', sortable: false, filterable: false },
      { text: '有効期間', value: '', sortable: false, filterable: false },
      { text: '備考', value: '', sortable: false, filterable: false },
    ],
    mobileHeaders: [
      { text: '車両番号', value: 'NUMBER', sortable: false, filterable: false },
      { text: '有効期間', value: '', sortable: false, filterable: false },
    ],

    accessibleVehicles: [],
    filteredVehicles: [],

    dialog: false,
    showedVehicle: '',
    deleteConfirmDialog: false,
    restoreDeletedConfirmDialog: false,

    parkings: [],
    selectedParking: null,

    searchCarNumber: null,

  }),
  computed: {
    pageItems: {
      get() {
        return [
          { text: '5', value: 5 },
          { text: '10', value: 10 },
          { text: '50', value: 50 },
          { text: '100', value: 100 },
          { text: 'ALL', value: this.accessibleVehicles.length }
        ]
      }
    },
    selectHeaders: {
      get() {
        return (
          this.$vuetify.breakpoint.name === 'xs' ||
          this.$vuetify.breakpoint.name === 'sm' ||
          this.$vuetify.breakpoint.name === 'md'
        ) ? this.mobileHeaders : this.headers
      }
    },
    registedDateFormatted () {
      return (value) => {
        return moment(value.create_dte).format('yyyy/MM/DD HH:mm:ss')
      }
    },
    carInfo () {
      return (value) => {
        return `${value.place} ${value.class_number} ${value.kana} ${value.car_number}`
      }
    },
    parkingName () {
      return (value) => {
        return (value.PARKING_COUNT === 1 || value.parking_id === 0) ? '全エリア・全フロア共通' : value.PARKING_NAME
      }
    },
    effectivePeriod() {
      return (value) => {
        const regexStart = /1000-01-01/
        const regexEnd = /9999-12-31/
        const whitespace = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
        if (value.deleted_at) {
          return "削除済みです"
        } else if (regexStart.test(value.start_datetime) && regexEnd.test(value.end_datetime)) {
          return ""
        } else if (regexStart.test(value.start_datetime)) {
          return `${whitespace}〜 ${moment(value.end_datetime).subtract(1, 'days').format('YY/MM/DD')}`
        } else if (regexEnd.test(value.end_datetime)) {
          return `${moment(value.start_datetime).format('YY/MM/DD')} 〜`
        } else {
          return `${moment(value.start_datetime).format('YY/MM/DD')} 〜 ${moment(value.end_datetime).subtract(1, 'days').format('YY/MM/DD')}`
        }
      }
    },
    nearDeadlineStyle() {
      return (value) => {
        return value.end_datetime !== null && moment(value.end_datetime).isSameOrBefore(moment().add(1, 'months')) ? 'near-deadline' : ''
      }
    },
    
  },
  watch: {
    selectedRequestClientStore(value) {
      if (value && value.address) {
        this.getFilteredAccessibleVehicles()
      }
    },
    searchCarNumber() {
      this.filterVehicles()
    },
    selectedParking() {
      this.filterVehicles()
    },
  },
  methods: {
    getFilteredAccessibleVehicles() {
      this.page = 1
      this.itemsPerPage = 10
      this.getAccessibleVehicles()
    },
    async getAccessibleVehicles() {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}accessible_vehicles?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      await this.axios
        .get(url)
        .then(response => {
          this.accessibleVehicles = response.data.accessible_vehicles
          this.setParkings()
          this.filterVehicles()
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    showDialog (vehicle) {
      this.showedVehicle = vehicle
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    edit () {
      this.$router.push({name: "editAccessibleVehicles", params: { id: this.showedVehicle.id, selectedRequestClientStore: this.selectedRequestClientStore, accessibleVehicle: this.showedVehicle }, props: true })
    },
    closeDeleteConfirmDialog () {
      this.deleteConfirmDialog = false
    },
    closeRestoreDeletedConfirm () {
      this.restoreDeletedConfirmDialog = false
    },
    async deleteVehicle() {
      this.deleteLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}accessible_vehicles/${this.showedVehicle.id}?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      let data = {
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
        parking_id: this.showedVehicle.parking_id,
        place: this.showedVehicle.place,
        class_number: this.showedVehicle.class_number,
        kana: this.showedVehicle.kana,
        car_number: this.showedVehicle.car_number,
      }
      const options = {
        url: url,
        method: 'DELETE',
        data: data,
      }
      await this.axios(options)
      .then(() => {
        this.$buefy.toast.open({
          message: `登録を削除しました`,
          type: "is-info"
        })
        this.getAccessibleVehicles()
        this.closeDeleteConfirmDialog()
        this.close()
        this.deleteLoading = false
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 404) {
          this.$buefy.toast.open({
            message: `対象の車両登録が見つかりません`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `登録削除に失敗しました`,
            type: "is-danger",
          })
        }
        this.closeDeleteConfirmDialog()
        this.deleteLoading = false
      })
    },
    async restoreDeletedVehicle() {
      this.restoreDeleteLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}accessible_vehicles/${this.showedVehicle.id}/restore_deleted_vehicle?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      let data = {
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
        parking_id: this.showedVehicle.parking_id,
        place: this.showedVehicle.place,
        class_number: this.showedVehicle.class_number,
        kana: this.showedVehicle.kana,
        car_number: this.showedVehicle.car_number,
      }
      const options = {
        url: url,
        method: 'PUT',
        data: data,
      }
      await this.axios(options)
      .then(() => {
        this.$buefy.toast.open({
          message: `登録を元に戻しました`,
          type: "is-info"
        })
        this.getAccessibleVehicles()
        this.closeRestoreDeletedConfirm()
        this.close()
        this.restoreDeleteLoading = false
      })
      .catch(err => {
            console.log(err.response)
            if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 404) {
          this.$buefy.toast.open({
            message: `対象の車両登録が見つかりません`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `削除を元に戻すのに失敗しました`,
            type: "is-danger",
          })
        }
        this.closeRestoreDeletedConfirm()
        this.restoreDeleteLoading = false
      })
    },
    filterVehicles() {
      // 全件を取得
      let vehicles = this.accessibleVehicles
      // 対象駐車場でフィルタリング
      if (this.selectedParking && this.selectedParking.PARKING_ID !== null) {
        vehicles = vehicles.filter(vehicle => 
          vehicle.parking_id === this.selectedParking.PARKING_ID
        )
      }
      // 車両番号でフィルタリング
      if (this.searchCarNumber) {
        vehicles = vehicles.filter(vehicle => 
          vehicle.car_number.includes(this.searchCarNumber)
        )
      }
      // フィルタリングした結果を返却
      this.filteredVehicles = vehicles
    },
    setParkings() {
      this.parkings = [
        { PARKING_ID: null, PARKING_NAME: '全て' },
        ...this.accessibleVehicles.map(vehicle => ({ // スプレッド演算子で配列を展開
          PARKING_ID: vehicle.parking_id,
          PARKING_NAME: vehicle.PARKING_NAME || '全エリア・全フロア共通'
        }))
      ]
    },
  },
  mounted() {
    this.getRequestClientStores()
  }
}
</script>
<style scoped>
.parked { background: #1F3BB333; color: #1F3BB3; }
.near-deadline { background: #F95F5333; color: #F95F53; }
.table-row { cursor: pointer; }
.custom-table { border-collapse: collapse; width: 100%; }
.custom-table th,.custom-table td { border: 1px solid #ccc; padding: 8px; text-align: center; vertical-align: middle; }
.header-cell { background-color: #2196f32b; }
.info-cell { background-color: #2196f32b; }
.custom-table tbody tr:hover { background-color: transparent !important; }

.deleted-item {
  color: lightslategray;
}

.effective-period {
  color: black;
  font-size: 16px;
}
</style>
