<template>
  <v-container>
    <v-card flat>
      <v-toolbar flat>
        <v-toolbar-title>アラート通知車両 新規登録</v-toolbar-title>
      </v-toolbar>

      <!-- 登録フォーム -->
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="店舗名"
                rules="required"
              >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :disabled="!$store.getters.isAdminUser"
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col v-if="selectedRequestClientStore && parkings.length > 2" cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="対象駐車場"
                rules="required"
              >
                <v-select
                  outlined
                  dense
                  v-model="selectedParking"
                  return-object
                  :items="parkings"
                  item-text="PARKING_NAME"
                  item-value="PARKING_ID"
                  label="対象駐車場"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <template v-if="selectedRequestClientStore">
            <!-- アラート通知車両情報 -->
            <v-row>
              <v-col cols="12" class="py-0">
                <small>アラート通知車両情報を入力してください<span class="red--text">(必須)</span></small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="地域"
                  rules="required|car_place"
                >
                  <v-text-field
                    outlined
                    dense
                    label="地域 (例: 旭川)"
                    v-model="carPlace"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="クラス"
                  rules="required|car_class"
                >
                  <v-text-field
                    outlined
                    dense
                    label="クラス (例: 500)"
                    v-model="carClass"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="かな"
                  rules="required|car_kana"
                >
                  <v-text-field
                    outlined
                    dense
                    label="かな (例: あ)"
                    v-model="carKana"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4桁ナンバー"
                  rules="required|car_number"
                >
                  <v-text-field
                    outlined
                    dense
                    label="4桁ナンバー (例: 3, 88, 456)"
                    v-model="carNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address" class="mb-5">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="teal"
                @click.stop="inOutRecordsDialog = true"
              >
                利用履歴から車両を再検索
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>

            <!-- アラートメール -->
            <v-row>
              <v-col cols="12" class="py-0">
                <small>アラートメール（任意）</small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>▽ カテゴリ</small>
                <small> ※選択すると件名と本文に参考文例が自動入力されます。自動入力後も編集可能です。</small>
              </v-col>
              <v-col cols="12" lg="3" class="pt-1">
                <v-select v-model="category" :items="categoryItems" item-text="category" return-object dense outlined hide-details label="カテゴリ"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <small>▽ 件名<span class="red--text"> ※設定しない場合は、「アラート車両が入庫しました。」がアラートメールの件名に表示されます。</span></small>
                <v-text-field v-model="alertTitle" dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <small>▽ 本文<span class="red--text"> ※設定しない場合は、「登録理由」に登録したものがアラートメールの本文に表示されます。</span></small>
                <v-textarea v-model="alertText" dense outlined hide-details></v-textarea>
              </v-col>
            </v-row>
            <!-- 登録画面で入出庫一覧から画像を選択した場合 -->
            <template v-if="imagePath != null">
              <v-row>
                <v-col cols="12" class="py-0">
                  <small>車両画像（任意）</small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" lg="3" class="py-0">
                  <v-card class="mx-auto" outlined>
                    <div class="blur" :style="bgimage"></div>
                  </v-card>
                </v-col>
                <v-col cols="2" lg="3" class="py-0">
                  <v-btn small outlined color="error" class="ml-2" @click="imagePath = null">削除</v-btn>
                </v-col>
              </v-row> 
            </template>

            <!-- 通知先メールアドレス -->
            <v-row>
              <v-col cols="12" class="pb-0">
                <small>通知先メールアドレスを選択して下さい(複数選択可)<span class="red--text">(必須)</span></small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>

            <!-- 店舗の通知先メールアドレスの表示 -->
            <v-row>
              <v-col cols="12" lg="6" class="py-0">
                <template>
                  <v-list flat>
                    <v-list-item-group
                      v-if="individualAlertAddresses.length != 0"
                      v-model="addingAddresses"
                      multiple
                      active-class=""
                    >
                      <v-list-item v-for="(item) in individualAlertAddresses" :key="item" :value="item" dense style="height: 20px;">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active" :true-value="item" color="primary"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>{{ item }}</v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-btn small outlined color="cyan" @click.stop="openAddAddress">追加</v-btn>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0">
                <small>
                  登録理由を入力して下さい(任意)
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="登録理由"
                  rules="max:255"
                >
                  <v-textarea
                    outlined
                    dense
                    v-model="note"
                    no-resize
                    rows="4"
                    label="登録理由"
                    counter="255"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </template>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="brue-gray" class="mr-2" to="/">戻る</v-btn>
          <v-btn small outlined color="primary" :disabled="invalid" @click.stop="openDialog">登録内容確認</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </ValidationObserver>

      <!-- 確認フォーム -->
      <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <v-card-title>登録内容確認</v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-row>
              <small class="my-5">以下の内容で登録します。よろしいですか？</small>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="店舗名"
                  :value="selectedRequestClientStore ? selectedRequestClientStore.name : ''"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-if="parkings.length > 2" cols="12" class="py-0">
                <v-text-field
                  label="対象駐車場"
                  v-model="selectedParking.PARKING_NAME"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="ナンバープレート情報"
                  v-model="numberInfo"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="アラートメール件名"
                  v-model="alertTitle"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  label="アラートメール本文"
                  :value="alertText"
                  no-resize
                  rows="4"
                  readonly
                ></v-textarea>
              </v-col>
              <template v-if="imagePath != null">
                <v-col cols="12" class="pt-0">
                  <small>車両画像</small>
                  <div class="blur" :style="bgimage"></div>
                </v-col>
              </template>
              <v-col cols="12" class="pb-0">
                <v-textarea
                  dense
                  readonly
                  label="通知先"
                  :value="addingAddresses.toString()"
                  no-resize
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  :value="note"
                  no-resize
                  rows="4"
                  label="登録理由"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-grey" @click="dialog = false">修正する</v-btn>
            <v-btn small outlined color="primary" @click="save" :loading="isLoading">登録する</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- アドレス追加用のフォーム -->
      <v-dialog
        v-model="addAddressDialog"
        max-width="500px"
      >
        <v-card>
          <ValidationProvider
            v-slot="{ errors }"
            name="通知先メールアドレス"
            rules="email"
          >
            <v-card-text>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>▽ 追加したいメールアドレスを入力してください</small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-text-field
                  outlined
                  dense
                  label="通知先メールアドレス"
                  class="pr-2"
                  v-model="editedAddress"
                  :error-messages="errors"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small outlined color="blue-grey" @click="cancelAddAddress">キャンセル</v-btn>
              <v-btn small outlined color="primary" :disabled="errors.length != 0 || editedAddress == null" @click="addItem(editedAddress)">追加</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </ValidationProvider>
        </v-card>
      </v-dialog>
      <SearchInOutRecords
        :selectedRequestClientStore="selectedRequestClientStore"
        :inOutRecordsDialog="inOutRecordsDialog"
        searchFrom="alertVehicles"
        @applyRecord="applyRecord"
        @closeDialog="closeDialog"
      />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import qs from 'qs'
import SearchInOutRecords from '../../components/SearchInOutRecords'

export default {
  name: 'newAlertVehicles',
  components: {
    SearchInOutRecords,
  },
  data: () => ({
    request_client_stores: [],
    selectedRequestClientStore: null,

    parkings: [],
    selectedParking: {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},

    individualAlertAddresses: [],
    addingAddresses: [],
    sendAddingAddresses: [],

    dialog: false,
    inOutRecordsDialog: false,

    // 登録フォーム
    carPlace: '',
    carClass: '',
    carKana: '',
    carNumber: '',
    alertTitle: '',
    alertText: '',
    categoryItems: [
      { id: 1, category: '万引き車両', title: '【駐車場アラート】万引き車両が入庫しました', text: '万引き車両が入庫しました。\n警戒してください。'},
      { id: 2, category: '警戒車両', title: '【駐車場アラート】警戒対象車両が入庫しました', text: '警戒対象車両が入庫しました。\n警戒してください。'},
      { id: 3, category: '駐車料金未払い車両', title: '【駐車場アラート】駐車料金未払い車両が入庫しました', text: '駐車料金未払い車両が入庫しました。'},
      { id: 4, category: 'お得意様車両', title: '【駐車場アラート】お得意様(〇〇様)のお車が入庫しました', text: 'お得意様（〇〇様）のお車が入庫しました。\nお出迎え等の体制を取ってください。'},
      { id: 5, category: '店舗関係者車両', title: '【駐車場アラート】〇〇様の車両が入庫しました', text: '〇〇様の車両が入庫しました。'},
      { id: 6, category: 'その他（自由入力）', title: '【駐車場アラート】', text: ''}
    ],
    category: '',
    note: '',
    registDte: null,

    // 画像用
    imagePath: null,
    originalImagePath: null,

    // アドレス追加用
    editedAddress: null,
    addAddressDialog: false,

    isLoading: false,
  }),
  computed: {
    numberInfo () {
      return `${this.carPlace} ${this.carClass} ${this.carKana} ${this.carNumber}`
    },
    bgimage () {
      return `background: center / cover no-repeat url(${this.imagePath});`
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getParkings()
        this.selectedParking = {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}
        this.getIndividualAlertaddresses()
        this.inOutRecordsDialog = true
      }
    },
    category: function (object) {
      this.alertTitle = object.title
      this.alertText = object.text
    }
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    // 店舗の個別の通知先メールアドレスの一覧を取得
    getIndividualAlertaddresses () {
      const url = process.env.VUE_APP_BASE_URL + "individual_alert_addresses?request_client_store_id=" + this.selectedRequestClientStore.id
      this.axios
        .get(url)
        .then(response => {
          console.log(response)
          this.individualAlertAddresses = response.data.map((item) => item.MAIL_ADDRESS)
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `通知先情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getParkings () {
      const url = `${process.env.VUE_APP_BASE_URL}parkings?&host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      this.axios
        .get(url)
        .then(response => {
          this.parkings = [{PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}].concat(response.data)
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    // アドレス追加用のダイアログを表示
    openAddAddress () {
      this.addAddressDialog = true
    },
    // 登録・編集内容の確認画面を表示
    openDialog () {
      if (this.addingAddresses.length !== 0) {
        this.dialog = true
      } else {
        this.$buefy.toast.open({
          message: `通知先メールアドレスを選択または入力してください`,
          type: "is-danger",
        })
      }
    },
    // アドレス追加のキャンセル
    cancelAddAddress () {
      this.editedAddress = null
      this.addAddressDialog = false
    },
    // アドレスの追加
    addItem (mailAddress) {
      if (this.individualAlertAddresses.find(item => item == mailAddress) || this.addingAddresses.find(item => item == mailAddress)) {
        this.$buefy.toast.open({
          message: `すでに追加済のアドレスです`,
          type: "is-danger",
        })
      } else {
        this.individualAlertAddresses.push(mailAddress)
        this.addingAddresses.push(mailAddress)
      }
      this.cancelAddAddress()
    },
    // 入出庫一覧で選択したレコードの情報に置換
    async applyRecord (record) {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}alert_vehicles/image_download_check?image_path=${record.IMAGE_DATA_OBJECT_PATH}`
      await this.axios
        .get(url)
        .then(response => {
          console.log(response)
          this.carPlace = record.PLACE
          this.carClass = record.CLASS_NUMBER
          this.carKana = record.KANA
          this.carNumber = record.CAR_NUMBER.replace(/\*/g,'')
          if (response.data.status === 200) {
            this.originalImagePath = null
            this.imagePath = record.IMAGE_DATA_OBJECT_PATH
          } else if (response.data.status === 500) {
            this.$buefy.toast.open({
              message: `画像の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$buefy.toast.open({
            message: `画像の取得に失敗しました`,
            type: "is-danger",
          })
        })
      this.isLoading = false
      this.inOutRecordsDialog = false
    },
    // 入出庫一覧を閉じる
    closeDialog () {
      this.inOutRecordsDialog = false
    },
    // 登録処理
    async save () {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}alert_vehicles`
      if (!this.addingAddresses.includes("pit_field@pitdesign.jp")) {
        this.sendAddingAddresses.push(...this.addingAddresses)
        this.sendAddingAddresses.push("pit_field@pitdesign.jp")
      }
      let data = {
        black_list: {
          PARKING_ID: this.selectedParking.PARKING_ID,
          GROUP_ID: 0, // 0: 全体に固定
          KIND_ID: 1, // 1: 店舗指定に固定
          PLACE: this.carPlace,
          CLASS_NUMBER: this.carClass,
          KANA: this.carKana,
          CAR_NUMBER: ('****' + this.carNumber).slice(-4),
          NOTE: this.note,
          REGIST_DTE: moment().format('yyyy/MM/DD HH:mm:ss'),
          ALERT_TITLE: this.alertTitle,
          ALERT_TEXT: this.alertText
        },
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
        individual_addresses: this.sendAddingAddresses,
        image_path: this.imagePath
      }
      const options = {
        url: url,
        method: 'POST',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.$router.push({path: "/alert-vehicles"})
        this.$buefy.toast.open({
          message: `アラート通知車両を登録しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 409) {
          this.$buefy.toast.open({
            message: `この車両はすでに登録されています`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `アラート通知車両の登録に失敗しました`,
            type: "is-danger",
          })
        }
        this.isLoading = false
        this.dialog = false
      })
    },
  },
  mounted() {
    this.getRequestClientStores()
  },
}
</script>

<style scoped>
.list-content-style {
  white-space: pre-line;
}
.blur {
  position: relative;
  max-width: 100%;
  padding-bottom: 75%;
  background-size: cover;
  overflow: hidden;
}
.blur:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
  background-clip: content-box;
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  filter: blur(5px);
}
</style>
