<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>ダッシュボード</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" lg="4" class="pt-0 pb-md-0 pb-4">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selectedRequestClientStore"
              return-object
              hide-details
              :disabled="!$store.getters.isAdminUser || isLoading"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between align-center">
              <div>
                <v-btn color="primary" tile outlined @click="openUrl(`https://info.smartpark.jp/login`)" class="mx-1">帳票</v-btn>
                <v-btn color="primary" tile outlined @click="openUrl(`https://reserve-web.app.smartpark.jp/auth/facility-login`)" class="mx-1">予約管理</v-btn>
                <v-btn color="primary" tile outlined @click="storeDailySalesForecastDialog = true" class="mx-1" v-if="dashboardData && !isLoading">日別売上目標設定[店舗]</v-btn>
                <v-btn color="primary" tile outlined @click="areaDailySalesForecastDialog = true" class="mx-1" v-if="dashboardData && !isLoading">日別売上目標設定[エリア別]</v-btn>
              </div>
              <div v-if="dashboardData && !isLoading">
                <small class="mr-2">最終更新時刻<span class="mx-4">{{ formattedDateTime }}</span></small>
                <v-btn tile outlined small @click="updateTime"><v-icon>mdi-refresh</v-icon></v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <template v-if="dashboardData && !isLoading">
          <v-row>
            <v-col md="4" cols="12">
              <v-card tile height="150px" class="d-flex flex-column align-center justify-center">
                <div class="text-md-h4 text-h6 font-weight-bold">{{ formattedDate }}</div>
                <div class="text-subtitle-1">来場日</div>
              </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card tile height="150px" class="d-flex flex-column align-center justify-center">
                <div class="text-md-h4 text-h6 font-weight-bold">
                  {{ dashboardData.todayInCount }}
                  <span class="pl-1 text-subtitle-1 font-weight-bold">台</span>
                  <span :class="comparisonClass">
                    {{ vehicleCountDifference(dashboardData) }}%
                    <span class="pr-1 mb-1">
                      <v-icon small :color="comparisonColor">
                        mdi-arrow-{{ comparisonIcon }}-circle-outline
                      </v-icon>
                    </span>
                    (前日比)
                  </span>
                </div>
                <div class="text-subtitle-1">本日の入場車両台数</div>
              </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card tile height="150px" class="d-flex flex-column align-center justify-center">
                <div v-if="dashboardData" class="text-md-h4 text-h6 font-weight-bold green--text text--light-3">
                  {{ this.dashboardData.salesAchievementRate.toFixed(1) }}
                  <span class="pl-1 text-subtitle-1 font-weight-bold">%</span>
                </div>
                <div class="text-subtitle-1">日別売上達成率</div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card tile height="150px">
                <div class="font-weight-bold text-subtitle-1 py-1 px-2">本日の稼働状況</div>
                <v-container>
                  <v-row>
                    <v-col v-for="item in dashboardData.vacancyStatuses" :key="item.areaId" md="2" cols="6" class="pb-1">
                      <div class="font-weight-bold">{{ item.areaName }}</div>
                      <div
                        class="text-md-4 text-h4 font-weight-bold"
                        :class="getTextColor(item)"
                      >
                        {{ ((item.receivingCount/item.countJudgeFull)*100).toFixed(1) }}<span class="pl-1 text-subtitle-1 font-weight-bold">%</span>
                      </div>
                      <div class="mr-5">
                        <v-progress-linear
                          :value="(item.receivingCount/item.countJudgeFull)*100"
                          :color="getProgressColor(item)"
                          height="5"
                        ></v-progress-linear>
                      </div>
                      <div>{{ `(${item.receivingCount}/${item.countJudgeFull})` }}</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card tile>
                <div class="font-weight-bold text-subtitle-1 py-1 px-2">本日の売上状況</div>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <BarChart :chartData="chartData" :chartOptions="chartOptions" :width="500"></BarChart>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <v-container v-if="isLoading" class="text-center my-10">
        <v-progress-circular
          :size="70"
          :width="7"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>

    <!-- モーダル 売上目標設定/店舗 -->
    <v-dialog
      scrollable
      v-model="storeDailySalesForecastDialog"
      max-width="500px"
      class="custom-dialog"
    >
      <v-card>
        <v-card-title>日別売上目標設定[店舗]</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="px-12">
            <v-row>
              <v-col cols="12">
                <span>店舗の日別売上目標額を入力してください。</span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="売上目標額"
                  v-model="storeDailySalesForecast"
                  required
                  maxlength="10"
                  @input="validateStoreForm"
                  :style="{ width: '150px' }"
                  :rules="[value => value === '' || /^[1-9][0-9]{0,9}$/.test(value) || '不正な値です']"
                >
                  <template v-slot:append-outer>
                    <span class="currency-symbol">円</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  outlined
                  color="blue-grey"
                  @click="storeDailySalesForecastDialog = false"
                >閉じる</v-btn>
                <v-btn
                  small
                  outlined
                  color="primary"
                  @click="updateStoreDailySalesForecast"
                  :disabled="!isStoreFormValid"
                >送信</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- モーダル 日別売上目標設定/エリア別 -->
    <v-dialog
      scrollable
      v-model="areaDailySalesForecastDialog"
      max-width="500px"
      class="custom-dialog"
    >
      <v-card>
        <v-card-title>日別売上目標設定[エリア別]</v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text>
          <v-container class="px-12">
            <v-row>
              <v-col cols="12">
                <span>各エリアの日別売上目標額を入力してください。</span>
              </v-col>
            </v-row>
            <v-row v-for="(info, index) in allAreasInfo" :key="info[0]">
              <v-col cols="12">
                <v-text-field
                  :label="info[1]"
                  v-model="allAreasInfo[index][2]"
                  required
                  maxlength="10"
                  :rules="[value => value === '' || /^[1-9][0-9]{0,9}$/.test(value) || '不正な値です']"
                  @input="validateAreaAndBulkForms"
                  :style="{ width: '150px' }"
                >
                  <template v-slot:append-outer>
                    <span class="currency-symbol">円</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12">
                <span class="red--text">※すべてのエリアの日別売上目標額を一括で登録したい場合はこちらに金額を入力してください。</span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="一括登録"
                  v-model="bulkSalesForecast"
                  required
                  maxlength="10"
                  :rules="[value => value === '' || /^[1-9][0-9]{0,9}$/.test(value) || '不正な値です']"
                  @input="validateAreaAndBulkForms"
                  :style="{ width: '150px' }"
                >
                  <template v-slot:append-outer>
                    <span class="currency-symbol">円</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  outlined
                  color="blue-grey"
                  @click="areaDailySalesForecastDialog = false"
                >閉じる</v-btn>
                <v-btn
                  small
                  outlined
                  color="primary"
                  @click="updateAreaDailySalesForecasts"
                  :disabled="!isAreaFormValid"
                >送信</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import BarChart from '../../components/BarChart.vue'
import qs from 'qs'

export default Vue.extend({
    name: 'Dashboard',
    components: { BarChart },
    data: () => ({
      date: new Date(),
      selectedRequestClientStore: null,
      request_client_stores: [],
      dashboardData: null,
      chartOptions: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: { xAxes: { grid: { display: false } }, yAxes: { grid: { display: false }, ticks: { font: { size: 14, weight: 'bold' } } } },
        plugins: { legend: { display: false } }
      },
      // 例
      chartData: {
        // labels: ['予約', '障碍者', '一般西', '一般東', '第2'],
        labels: [],
        datasets: [
          { label: '売上', borderColor: '#1F3BB3', backgroundColor: '#1F3BB3', data: [], barThickness: 20 }
        ]
      },
      isLoading: false,

      vacancyStatuses: [],
      
      storeDailySalesForecastDialog: false,
      storeDailySalesForecast: '',
      isStoreFormValid: false,
      
      areaDailySalesForecastDialog: false,
      lid: null,
      allAreasInfo: [],
      bulkSalesForecast: '',
      isAreaFormValid: false,
    }),
    computed: {
      formattedDate () {
        const year = this.date.getFullYear()
        const month = this.date.getMonth() + 1
        const day = this.date.getDate()
        return `${year}年${month}月${day}日`
      },
      formattedDateTime () {
        const hours = this.date.getHours().toString().padStart(2, '0')
        const minutes = this.date.getMinutes().toString().padStart(2, '0')
        const seconds = this.date.getSeconds().toString().padStart(2, '0')
        return `${this.formattedDate}` + ' ' + `${hours}:${minutes}:${seconds}`
      },
      comparisonClass () {
        return {
          'pl-2 text-caption font-weight-bold': true,
          'green--text text--light-3': this.dashboardData && (this.dashboardData.todayInCount - this.dashboardData.yesterdayInCount) >= 0,
          'red--text text--light-3': this.dashboardData && (this.dashboardData.todayInCount - this.dashboardData.yesterdayInCount) < 0
        }
      },
      vehicleCountDifference () {
        return (value) => {
          const todayInCount = value.todayInCount || 0
          const yesterdayInCount = value.yesterdayInCount || 0
          if (yesterdayInCount === 0) {
            return todayInCount === 0 ? 0.0 : 100.0
          } else {
            const rate = (todayInCount - yesterdayInCount) / yesterdayInCount * 100
            const formattedRate = rate.toFixed(2)
            return parseFloat(formattedRate)
          }
        }
      },
      comparisonColor() {
        const difference = this.vehicleCountDifference(this.dashboardData)
        return difference >= 0 ? 'green light-3' : 'red light-3'
      },
      comparisonIcon() {
        const difference = this.vehicleCountDifference(this.dashboardData)
        return difference >= 0 ? 'top-right-thin' : 'bottom-right-thin'
      }
    },
    watch: {
      selectedRequestClientStore (value) {
        if (value && value.address) {
          this.getDashBoardData()
        }
      },
    },
    methods: {
      openUrl (url) {
        window.open(url, '_blank')
      },
      updateTime() {
        this.date = new Date()
        this.getDashBoardData()
      },
      getRequestClientStores () {
        const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
        this.axios
          .get(url)
          .then(response => {
            this.request_client_stores = response.data
            if (!this.$store.getters.isAdminUser) {
              this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
            }
          })
          .catch(err => {
            this.handleError(err, '店舗情報の取得に失敗しました。')
          })
      },
      async getDashBoardData () {
        this.isLoading = true
        this.dashboardData = null
        const params = `store_id=${this.selectedRequestClientStore.id}`
        const url = process.env.VUE_APP_BASE_URL + "dashboards?" + params
        try {
          const response = await this.axios.get(url)
          this.dashboardData = response.data
          this.chartData.labels = Object.keys(response.data.areaSales)
          this.chartData.datasets[0].data = Object.values(response.data.areaSales)
          this.lid = response.data.lid
          this.areaNumbersAndNames = response.data.areaNumbersAndNames
          this.vacancyStatuses = response.data.vacancyStatuses
          this.allAreasInfo = response.data.allAreasInfo
          this.storeDailySalesForecast = response.data.storeDailySalesForecast
        } catch (err) {
          console.log(err)
          this.handleError(err, '入出場データの取得に失敗しました。')
        } finally {
          this.isLoading = false
        }
      },
      handleError (err, message) {
        if (this.$route.path.match('/login')) {
          return
        }
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: 'is-denger'
          })
          this.$store.commit('clearUser')
          this.$router.push({ path: '/login' })
        } else {
          this.$buefy.toast.open({
            message,
            type: 'is-danger'
          })
        }
      },
      async updateStoreDailySalesForecast() {
        if (!confirm('店舗の日別売上目標額を更新しますか？')) return
        const params = `store_id=${this.selectedRequestClientStore.id}`
        const url = `${process.env.VUE_APP_BASE_URL}dashboards/update_store_daily_sales_forecast?${params}`
        const data = {
          daily_sales_forecast: this.storeDailySalesForecast
        }
        try {
          await this.axios.put(url, qs.stringify({ data }))
          this.$buefy.toast.open({
            message: `店舗の日別売上目標額を更新しました`,
            type: "is-info"
          })
        } catch (err) {
          console.log(err)
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else if (err.response && err.response.status == 406) {
            this.$buefy.toast.open({
              message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
              type: "is-danger",
            })
          } else if (err.response && err.response.status == 409) {
            this.$buefy.toast.open({
              message: `すでに登録されています`,
              type: "is-danger",
            })
          } else {
            this.$buefy.toast.open({
              message: `更新に失敗しました`,
              type: "is-danger",
            })
          }
        }
        this.storeDailySalesForecastDialog = false
      },
      async updateAreaDailySalesForecasts() {
        if (!confirm('各エリアの日別売上目標額を更新しますか？')) return
        // 一括登録に値がある場合、一括登録の値で他の全ての値を更新
        if (this.bulkSalesForecast !== '') {
          this.allAreasInfo.forEach((info) => {
            this.$set(info, 2, this.bulkSalesForecast)
          })
        }
        const data = this.allAreasInfo.map(info => ({
          store_id: this.selectedRequestClientStore.id,
          lid: this.lid,
          area_no: info[0],
          area_name: info[1],
          daily_sales_forecast: info[2]
        }))
        const url = `${process.env.VUE_APP_BASE_URL}dashboards/update_area_daily_sales_forecasts`
        try {
          await this.axios.put(url, qs.stringify({ data }))
          this.$buefy.toast.open({
            message: `各エリアの日別売上目標額を更新しました`,
            type: "is-info"
          })
        } catch (err) {
          console.log(err.response)
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else if (err.response && err.response.status == 406) {
            this.$buefy.toast.open({
              message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
              type: "is-danger",
            })
          } else if (err.response && err.response.status == 409) {
            this.$buefy.toast.open({
              message: `すでに登録されています`,
              type: "is-danger",
            })
          } else {
            this.$buefy.toast.open({
              message: `更新に失敗しました`,
              type: "is-danger",
            })
          }
        }
        this.bulkSalesForecast = ''
        this.areaDailySalesForecastDialog = false
      },
      getTextColor(item) {
        const percentage = (item.receivingCount/item.countJudgeFull)*100
        if (percentage >= 90) {
          return 'red--text'
        } else if (percentage >= 70) {
          return 'orange--text'
        } else {
          return 'blue--text'
        }
      },
      getProgressColor(item) {
        const percentage = (item.receivingCount/item.countJudgeFull)*100
        if (percentage >= 90) {
          return 'red'
        } else if (percentage >= 70) {
          return 'orange'
        } else {
          return 'blue'
        }
      },
      validateStoreForm() {
        this.isStoreFormValid = /^[1-9][0-9]{0,9}$/.test(this.storeDailySalesForecast);
      },
      validateAreaAndBulkForms() {
        const allAreasValid = this.allAreasInfo.every(info => (info[2] === null) || (info[2] === '') || /^[1-9][0-9]{0,9}$/.test(info[2]));
        const bulkInputValid = (this.bulkSalesForecast === '') || /^[1-9][0-9]{0,9}$/.test(this.bulkSalesForecast);
        const hasAnyAreaValue = this.allAreasInfo.some(info => (info[2] !== null) && (info[2] !== ''));
        this.isAreaFormValid = allAreasValid && bulkInputValid && (hasAnyAreaValue || this.bulkSalesForecast !== '');
      },
    },
    mounted () {
      this.date = new Date()
      this.getRequestClientStores()
    }
})
</script>
<style scoped>

</style>